html {
  margin: 0 auto;
  max-width: 768px;
  font-size: 4vw;
}

@media (min-width: 768px) {
  html {
    font-size: 30.72px;
  }
}

#carousel .carousel-caption {
  font-size: 0.75em;
  color: rgb(50, 50, 50);
}

#carousel .carousel-item:nth-of-type(n+5) .carousel-caption {
  color: #fff;
}

#carousel figure {
  margin: 17em 0 0;
  text-shadow: #000 1px 1px 2px;
  color: #fff;
}

#carousel blockquote {
  font-weight: bolder;
  font-family: cursive;
}

#carousel blockquote p::before {
  content: '\201C';
}

#carousel blockquote p::after {
  content: '\201D';
}

#carousel figcaption {
  font-size: 0.75em;
  color: inherit;
}

#intro {
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  text-align: center;
  text-shadow: #000 1px 1px;
  color: #fff;
}

.names {
  font: 700 2.5em cursive;
}

#details {
  margin: 1rem;
  border: 0.25rem solid rgb(182, 143, 52);
  color: rgb(140, 139, 142);
}

#details section {
  margin: 1em;
  text-align: center;
}

#details h2 {
  font-family: cursive;
  color: rgb(182, 143, 52);
}

#details time {
  display: block;
}

#details a:focus {
  outline-color: rgb(182, 143, 52);
}

#details p, #details chr-countdown {
  font-size: 0.75em;
}

address .name {
  color: inherit;
}

.rsvp {
  text-decoration: none;
  font-family: var(--bs-font-sans-serif);
  color: inherit;
}

.rsvp::before {
  content: '☞ ';
}

.rsvp::after {
  content: ' ☜';
}
